@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap'");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700");
/* @import url('https://webfontworld.github.io/pretendard/Pretendard.css'); */

/* modal - dialog */
.swal-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	font-size: 0;
	overflow-y: auto;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 10000;
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.3s;
}

.swal-overlay:before {
	content: " ";
	display: inline-block;
	vertical-align: middle;
	height: 100%;
}

.swal-overlay--show-modal {
	opacity: 1;
	pointer-events: auto;
}

.swal-overlay--show-modal .swal-modal {
	opacity: 1;
	pointer-events: auto;
	box-sizing: border-box;
	animation: showSweetAlert 0.3s;
	will-change: transform;
}

.swal-modal {
	width: 100%;
	max-width: 478px;
	opacity: 0;
	pointer-events: none;
	background-color: #ffffff;
	text-align: center;
	border-radius: 5px;
	position: static;
	margin: 20px auto;
	display: inline-block;
	vertical-align: middle;
	transform: scale(1);
	transform-origin: 50% 50%;
	z-index: 10001;
	transition: transform 0.3s, opacity 0.2s;
	font-family: "Pretendard", "Noto Sans KR", "IBM Plex Sans", sans-serif;
	padding: 20px;
}

.swal-icon>div {
	margin: 0 auto;
}

.swal-icon .logo {
	width: 58px;
}

.swal-icon .img-msg {
	width: 164px;
	margin-top: 15px;
}

.swal-icon img {
	width: inherit;
	-o-object-fit: cover;
	object-fit: cover;
}

.swal-body {
	margin-top: 15px;
	padding: 0;
}

.swal-body>div {
	margin: 0 auto;
}

.swal-body .img-msg {
	width: 164px;
}

.swal-body img {
	width: inherit;
	-o-object-fit: cover;
	object-fit: cover;
}

.swal-footer {
	display: flex;
	padding: 0;
	padding-top: 15px;
	margin-top: 15px;
	border-radius: inherit;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.swal-title {
	padding: 0;
	font-size: 18px;
	font-weight: 700;
	color: #333333;
}

.swal-title:first-child {
	margin-top: 10px;
}

.swal-title:not(:last-child) {
	margin-bottom: 30px;
}

.swal-title {
	padding: 0;
	font-size: 18px;
	font-weight: 700;
	color: #333333;
}

.swal-text {
	padding: 0;
	color: #808080;
	font-size: 16px;
	font-weight: 500;
	text-align: center;
}

.swal-button-container {
	display: flex;
	position: relative;
	width: 100%;
	gap: 5px;
}

.swal-button-container .swal-button {
	width: inherit;
	height: 50px;
	font-size: 18px;
	font-weight: 500;
	padding: 10px;
	border-radius: 5px;
}

.swal-button-container .swal-button:focus {
	outline: none;
	box-shadow: none;
}

.swal-button-container .swal-button--confirm {
	background-color: #ff8000;
	color: #ffffff;
}

.swal-button-container .swal-button--confirm:hover {
	background-color: #ff8000;
}

.swal-button-container .swal-button--cancel {
	background-color: #eeeeee;
}

.swal-button-container .swal-button--cancel:hover {
	background-color: #eeeeee;
}

/* media query */
@media (max-width: 500px) {
	.swal-modal {
		width: calc(100% - 20px);
	}
}